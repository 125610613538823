<template>
  <el-card class="box-card">
    <el-col :span="12">
      <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加专题活动</el-button>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="活动名称:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入活动名称"
              size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>


    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="name"
          label="专属活动名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="slogan"
          label="宣传语">
      </el-table-column>
      <el-table-column align="center" label="开始时间">
        <template slot-scope="scope">
          {{ scope.row.begin_time | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="结束时间">
        <template slot-scope="scope">
          {{ scope.row.end_time | formatDate }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterState"
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiDelProjectActivities, apiGetProjectActivities} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      query_time: [],
      imgShow: true,
      selectRows: '',
      listLoading: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'thematicActivities/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'thematicActivities/revise',
          query: {
            id: '',
          }
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        if (row.id) {
          this.$confirm('你确定要操作当前项吗', null, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            apiDelProjectActivities({id: row.id}).then(res => {
              if (res.code == 200) {
                this.$message.success(res.message)
                this.fetchData()
              } else {
                this.$message.error(res.message)
              }
            })
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetProjectActivities(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始'
          : row.state == '1' ? '进行中'
              : row.state == '2' ? '已结束'
                  : '暂无'
    },
  }
}

</script>
